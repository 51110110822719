import React from "react"
import styled from "styled-components"
// import { Squash as Hamburger } from "hamburger-react"
import /* Body */ "../theme/typography"
import Button from "./Button"
import { FiArrowLeft } from "react-icons/fi"
// import { navigate } from "gatsby"

const NavContainer = styled.div`
  display: flex;
  padding: 24px 0 0 0;
  justify-content: start;

  justify-self: center;
  width: 100%;
`

const Navbar = () => {
  return (
    <NavContainer>
      <Button to="/">
        {
          //<FiHome />
        }
        <FiArrowLeft />
        Home
      </Button>
    </NavContainer>
  )
}

export default Navbar

/* const NavbarContainer = styled.div`
  padding: 0px 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;
  height: 64px;

  @media (max-width: 800px) {
    padding: 1rem 1.5rem;
  }
`

const NavbarList = styled.div`
  display: flex;
  transition: top 0.25s ease-out;
  width: 100%;
  padding: 0 24px;
  justify-content: space-between;
  max-width: 520px;
  align-items: center;

  @media screen and (max-width: 800px) {
    height: ${props => (props.state ? "auto" : "0px")};
    grid-auto-flow: row;
    position: absolute;
    padding: 0 24px;
    align-items: center;
    left: 0;
    right: 0;
    top: 64px;
    overflow: hidden;
    z-index: -10;
  }
`

const HamburgerWrapper = styled.div`
  display: none;

  @media screen and (max-width: 800px) {
    display: block;
  }
` */

/* class Navbar extends React.Component {
  state = {
    isActive: false,
  }

  handleClick = () => {
    this.setState(state => ({ isActive: !state.isActive }))
  }

  render() {
    return (
      <NavbarContainer>
        <NavbarList state={this.state.isActive}>
          <Button to="/">Home</Button>
          <Button to="/projects">Projects</Button>
          <Button to="/photography">Photos</Button>
          <Button to="/blog">Blog</Button>
        </NavbarList>
        <HamburgerWrapper onClick={this.handleClick}>
          <Hamburger
            size={24}
            rounded
            duration={0.25}
            easing="ease-in"
            color={"#090B18"}
          />
        </HamburgerWrapper>
      </NavbarContainer>
    )
  }
} */
