import * as React from "react"
import styled, { css } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Body, Header3, Header5, Caption, Bold } from "../theme/typography"
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
// import { Link } from "gatsby"

var slugify = require("slugify")

const MockupContainer = styled.div`
  padding-top: 57.8%;
  position: relative;
  display: block;
  justify-self: stretch;

  border: 3px solid var(--base7);
  margin-bottom: 8px;
  border-radius: 0 0 8px 8px;
  z-index: 100;
`

const RichCaption = styled(Caption)`
  color: var(--base11);
  text-align: center;
  display: block;
  margin-bottom: 24px;
`

const MockupWrapper = styled.div`
  position: absolute;
  top: -0.5px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 10;
  border-radius: 0 0 5px 5px;
`

const WindowUI = styled.div`
  background: var(--base7);
  padding: 0.5vw 1vw;
  display: grid;
  justify-self: stretch;
  grid-auto-flow: column;
  gap: 8px;
  justify-content: start;
  border: 3px solid var(--base7);
  border-bottom: none;
  border-radius: 8px 8px 0 0;

  div {
    width: 1vw;
    height: 1vw;
    min-width: 8px;
    min-height: 8px;
    max-width: 12px;
    max-height: 12px;
    border-radius: 50%;
    background: #f4bf4e;
    border: 1px solid rgba(0, 0, 0, 0.1);

    :first-of-type {
      background: #ed6a5e;
    }

    :last-of-type {
      background: #62c554;
    }
  }
`

const Header3Rich = styled(Header3)`
  margin-top: 32px;
  width: 100%;
  color: var(--base12);
`

const Header5Rich = styled(Header5)`
  margin-top: 32px;
  width: 100%;
  color: var(--base12);
`

const BodyRich = styled(Body)`
  margin-top: 24px;
  width: 100%;
  display: block;

  color: var(--base12);
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--base6);
  margin-top: 32px;
`

const ImageRich = styled(GatsbyImage)`
  margin-top: 24px;
  margin-bottom: 12px;
  border-radius: 6px;
  overflow: hidden;
`

const LinkRichStyles = css`
  text-decoration: underline;
  color: var(--neutral12);
  :hover {
    color: var(--accent11);
  }
`

/* const LinkRich = styled(Link)`
  ${LinkRichStyles}
` */

const AhrefRich = styled.a`
  ${LinkRichStyles}
`

const ULRich = styled.ul`
  margin-top: 8px;
  color: var(--base12);
  list-style: disc outside;
  padding-left: 32px;

  > li {
    margin-top: 8px;
  }
`
const OLRich = styled.ol`
  margin-top: 8px;
  color: var(--base12);
  list-style: decimal outside;
  padding-left: 32px;

  > li {
    margin-top: 8px;
  }
`

const Mockup = ({ mockup }) => {
  return (
    <div
      style={{
        width: "100%",
        marginTop: "32px",
      }}
    >
      <div
        style={{
          margin: "0 auto",
          display: "grid",
          gridAutoFlow: "row",
          gap: 0,
          width: "100%",
          justifyItems: "center",
        }}
      >
        <WindowUI>
          <div />
          <div />
          <div />
        </WindowUI>
        <MockupContainer>
          <MockupWrapper>
            <GatsbyImage
              image={mockup.image.gatsbyImageData}
              style={{ zIndex: "0" }}
              alt={mockup.image.title}
            />
          </MockupWrapper>
        </MockupContainer>
        <RichCaption>{mockup.title}</RichCaption>
      </div>
    </div>
  )
}

const options = {
  renderMark: {
    [MARKS.BOLD]: text => {
      return <Bold>{text}</Bold>
    },
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <BodyRich>{children}</BodyRich>,
    [BLOCKS.HEADING_3]: (node, children) => (
      <Header3Rich
        id={slugify(String(children), {
          remove: /[*+~.()'"!:@]/g,
          lower: true,
        })}
      >
        {children}
      </Header3Rich>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <Header5Rich
        id={slugify(String(children), {
          remove: /[*+~.()'"!:@]/g,
          lower: true,
        })}
      >
        {children}
      </Header5Rich>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      return <Mockup mockup={node.data.target} />
    },
    [BLOCKS.HR]: node => <Divider />,
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <AhrefRich href={node.data.uri} target="_blank">
          {node.content[0].value}
        </AhrefRich>
      )
    },
    [BLOCKS.UL_LIST]: node => {
      return (
        <ULRich>
          {node.content.map(item => {
            return (
              <li>
                <Body>{item.content[0].content[0].value}</Body>
              </li>
            )
          })}
        </ULRich>
      )
    },
    [BLOCKS.OL_LIST]: node => {
      return (
        <OLRich>
          {node.content.map(item => {
            return (
              <li>
                <Body>{item.content[0].content[0].value}</Body>
              </li>
            )
          })}
        </OLRich>
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      console.log(node.data)
      return (
        <>
          <ImageRich
            image={node.data.target.gatsbyImageData}
            alt={node.data.target.title}
            imgStyle={{ borderRadius: 6 }}
          />
          <RichCaption>{node.data.target.description}</RichCaption>
        </>
      )
    },
  },
}

const RichText = ({ richText }) => {
  console.log(richText.raw)
  return <>{renderRichText(richText, options)}</>
}

export default RichText
export { Divider }
