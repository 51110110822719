import * as React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const buttonStyle = `
color: var(--base11);
  text-decoration: none;
  padding: 12px 16px;
  margin: 4px;
  background: var(--base4);
  border-radius: 4px;
  font-size: 16px;
  display: inline-grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  align-content: center;
  border: 1px solid var(--base6);


  :hover {
    color: var(--base12);
    background: var(--base6);
    border: 1px solid var(--base8);
  }
`

const StyledLink = styled(Link)`
  ${buttonStyle}
`

const StyledA = styled.a`
  ${buttonStyle}
`

const Button = props => {
  if (props.to) {
    return <StyledLink {...props}>{props.children}</StyledLink>
  } else if (props.href) {
    return <StyledA {...props}>{props.children}</StyledA>
  }
}

export default Button
