import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/Layout"
import { Body, Header1 } from "../theme/typography"
import RichText from "../components/RichText"
import { GatsbyImage } from "gatsby-plugin-image"
import Navbar from "../components/Navbar"
import Seo from "../components/Seo"

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-auto-flow: row;
  padding: 0 0 24px 0;
  gap: 0;
  justify-items: stretch;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  justify-self: center;
`

const TitleContainer = styled.div`
  color: var(--base12);
  padding: 32px 0 12px 0;
  display: grid;
  grid-auto-flow: row;
  text-align: start;
  gap: 0;
  justify-content: stretch;

  justify-self: center;

  > ${Header1} {
    margin-top: 64px;
    text-align: start;
  }

  > ${Body} {
    margin-top: 12px;
    color: var(--base11);
  }
`

const FeaturedImage = styled(GatsbyImage)`
  border-radius: 8px;
  overflow: visible;
  position: relative;
  z-index: 10;

  ::before {
    content: "";
    position: absolute;
    top: 8px;
    right: 0;
    bottom: -8px;
    left: 0;
    background: var(--base11);
    z-index: 0;
    filter: blur(32px);
    opacity: 0.25;
  }

  > * {
    border-radius: 2px;
    overflow: hidden;
  }
`

const ProjectTemplate = ({ data, pageContext }) => {
  const project = data.contentfulProject

  var Color1 = pageContext.palette.LightMuted.rgb
  var Color2 = pageContext.palette.Muted.rgb

  var Color3 = pageContext.palette.DarkMuted.rgb

  Color1 = `${Color1[0]}, ${Color1[1]}, ${Color1[2]}`
  Color2 = `${Color2[0]}, ${Color2[1]}, ${Color2[2]}`
  Color3 = `${Color3[0]}, ${Color3[1]}, ${Color3[2]}`

  return (
    <Layout>
      <Seo title={project.title} />
      <Navbar />
      <TitleContainer Color1={Color1} Color2={Color2} Color3={Color3}>
        <FeaturedImage
          shadow={Color3}
          image={project.featuredImage.gatsbyImageData}
          alt={project.featuredImage.title}
          imgStyle={{ borderRadius: 8 }}
        />
        <Header1>{project.title}</Header1>
        <Body>{project.subtitle}</Body>
      </TitleContainer>
      <BodyContainer>
        <RichText richText={project.body} />
      </BodyContainer>
    </Layout>
  )
}

export default ProjectTemplate

export const query = graphql`
  query ($id: String) {
    contentfulProject(id: { eq: $id }) {
      title
      contentful_id
      subtitle
      featuredImage {
        title
        gatsbyImageData
      }
      body {
        raw
        references {
          ... on ContentfulMockup {
            title
            contentful_id
            __typename
            image {
              gatsbyImageData(width: 1600, quality: 100)
            }
          }
          ... on ContentfulAsset {
            gatsbyImageData
            title
            contentful_id
            __typename
            description
          }
        }
      }
    }
  }
`
